var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"partner-table"},[_c('div',{staticClass:"partner-table__actions"},[_c('v-btn',{staticClass:"actions__create",attrs:{"large":"","depressed":"","color":"primary","disabled":!_vm.canAdd},on:{"click":function($event){return _vm.addPartner()}}},[_vm._v(" "+_vm._s(_vm.$t('globals.add'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1),(_vm.searchBar)?_c('AppSearch',{staticClass:"actions__search",on:{"change":_vm.onSearchChange}}):_vm._e()],1),_c('base-list',{staticClass:"mt-4",attrs:{"backgroundColor":_vm.backgroundColor,"headerProps":{ sortIcon: null },"headers":_vm.headers,"items":_vm.partners,"search":_vm.search,"loading":_vm.partnersAreLoading,"footerProps":{
      itemsPerPageText: _vm.$t('globals.datatable.itemPerPage'),
      itemsPerPageAllText: _vm.$t('globals.datatable.itemPerPageAll'),
      pageText: _vm.$t('globals.datatable.of'),
    },"noDataText":_vm.$t('globals.datatable.noData'),"noResultsText":_vm.$t('globals.datatable.noResult')},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.type",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.type)+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('admin-table-actions',{attrs:{"user":_vm.user,"has-delete":true,"can-edit":_vm.canEdit,"can-delete":_vm.canDelete},on:{"edit-item":function($event){return _vm.editPartner(item)},"delete-item":function($event){return _vm.openDeletePartnerDialog(item)}}})]}}],null,true)}),_c('dialog-confirmation',{attrs:{"visible":_vm.dialog.delete,"title":_vm.$t('admin.partners.delete.deleteConfirmationDialog.title'),"content":_vm.$t('admin.partners.delete.deleteConfirmationDialog.content')},on:{"cancel":function($event){return _vm.closeDialog('delete')},"confirm":_vm.deletePartner}}),(_vm.dialog.edit)?_c('v-dialog',{attrs:{"width":"800"},on:{"click:outside":_vm.closeDialog},model:{value:(_vm.dialog.edit),callback:function ($$v) {_vm.$set(_vm.dialog, "edit", $$v)},expression:"dialog.edit"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.event.name)+" - "+_vm._s(_vm.$t(("admin.partners.editModal.title." + _vm.modalTitle)))+" ")]),_c('v-card-text',[_c('edit-partner-admin',{attrs:{"eventId":_vm.eventId,"partnerId":_vm.partnerId},on:{"closeDialog":function($event){return _vm.closeDialog('edit')}}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }