<template>
  <v-container class="edit-conference">
    <h1 class="edit-conference__title">
      {{ event.name }} -
      {{ isEditing ? $t('conference.editModal.title') : $t('conference.addModal.title') }}
    </h1>

    <v-container class="edit-conference__content">
      <v-tabs v-if="isEditing" v-model="conferenceTab">
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab href="#conference">{{ $t('conference.edit.title') }}</v-tab>
        <v-tab href="#partners" :disabled="!canViewPartners">
          {{ $t('admin.partners.title') }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="conferenceTab">
        <v-tab-item value="conference">
          <v-form :disabled="!canUpdatePartial" v-model="valid" ref="form">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="conferenceForm.name"
                  :label="$t('conference.editModal.name')"
                  :rules="rules.name"
                  hide-details="auto"
                  :persistent-placeholder="true"
                  placeholder="-"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <DateTimePicker :label="$t('conference.editModal.startTime')" v-model="startTime">
                  <template slot="actions" slot-scope="{ parent }">
                    <v-btn color="grey darken-1" text @click="parent.display = false"
                      >{{ $t('globals.cancel') }}
                    </v-btn>

                    <v-btn color="green darken-1" text @click="parent.okHandler"
                      >{{ $t('globals.ok') }}
                    </v-btn>
                  </template>

                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>

                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </DateTimePicker>
              </v-col>

              <v-col cols="12" sm="6">
                <DateTimePicker
                  :label="$t('conference.editModal.endTime')"
                  v-model="endTime"
                  :textFieldProps="{ rules: endDateRules }"
                  :datePickerProps="{ allowedDates: allowedDates }"
                >
                  <template slot="actions" slot-scope="{ parent }">
                    <v-btn color="grey darken-1" text @click="parent.display = false"
                      >{{ $t('globals.cancel') }}
                    </v-btn>

                    <v-btn color="green darken-1" text @click="parent.okHandler"
                      >{{ $t('globals.ok') }}
                    </v-btn>
                  </template>

                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>

                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </DateTimePicker>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="conferenceForm.roomId"
                  :items="conferenceRooms"
                  item-value="id"
                  item-text="name"
                  :label="$t('conference.editModal.roomId')"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-textarea
                  :label="$t('conference.editModal.description')"
                  :rows="2"
                  auto-grow
                  v-model="conferenceForm.description"
                  hide-details="auto"
                  :persistent-placeholder="true"
                  placeholder="-"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="conferenceForm.hasChatRoom"
                  :label="$t('conference.editModal.hasChatRoom')"
                  :disabled="!canUpdatePartial"
                  hide-details
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-checkbox
                  v-model="conferenceForm.public"
                  :label="$t('conference.editModal.isPublic')"
                  :disabled="!canUpdatePartial"
                  hide-details
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-if="isEditing && conferenceForm.hasChatRoom"
                  :disabled="!conferenceForm.hasChatRoom || !canUpdate"
                  :label="$t('conference.editModal.chatRoom')"
                  v-model="conferenceForm.chatId"
                  hide-details="auto"
                  :persistent-placeholder="true"
                  placeholder="-"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" :sm="isZoom ? 12 : 6" :md="4">
                <v-select
                  v-model="conferenceForm.videoType"
                  :items="videoTypes"
                  :label="$t('conference.editModal.videoType')"
                  :disabled="!canUpdatePartial"
                  :rules="[rules.videoTypeRequired]"
                />
              </v-col>

              <template v-if="isZoom">
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="conferenceForm.zoomMeetingId"
                    :label="$t('conference.editModal.videoId')"
                    :disabled="!canUpdatePartial"
                    hide-details="auto"
                    :persistent-placeholder="true"
                    placeholder="-"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="conferenceForm.zoomMeetingPassword"
                    :label="$t('conference.editModal.videoPassword')"
                    :disabled="!canUpdatePartial"
                    hide-details="auto"
                    :persistent-placeholder="true"
                    placeholder="-"
                  />
                </v-col>
              </template>

              <template v-if="isVimeo">
                <v-col cols="12" sm="6" md="8">
                  <v-text-field
                    v-model="conferenceForm.vimeoUrl"
                    :label="$t('conference.editModal.videoUrl')"
                    :disabled="!canUpdatePartial"
                    hide-details="auto"
                    :persistent-placeholder="true"
                    placeholder="-"
                  />
                </v-col>
              </template>

              <template v-if="isUmanize">
                <v-col cols="12" sm="6" md="8">
                  <p class="video-url__title">
                    {{ $t('conference.editModal.videoUrl') }}
                    <info-tooltip v-if="!conferenceForm.vimeoUrl">{{
                      $t('conference.editModal.videoUrl-tooltip')
                    }}</info-tooltip>
                  </p>
                  <v-text-field
                    v-model="conferenceForm.vimeoUrl"
                    :disabled="!canUpdate"
                    hide-details="auto"
                    :persistent-placeholder="true"
                    placeholder="-"
                  />
                </v-col>
              </template>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-label>{{ $t('conference.editModal.coverImages') }}</v-label>

                <base-input-multiple
                  :placeholder="$t('conference.edit.cover-images.placeholder')"
                  :values="conferenceForm.coverImages"
                  :has-internal-add="false"
                  :disabled="!canUpdatePartial"
                  @on-change="onMultipleChange('coverImages', $event)"
                  @add="openPictureDialog('coverImages')"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-label>{{ $t('conference.editModal.documents') }}</v-label>

                <base-input-multiple
                  :values="conferenceForm.documents"
                  :has-internal-add="false"
                  :is-object-array="true"
                  @on-change="updateDocuments"
                  @add="openDialogDocuments"
                />
              </v-col>
            </v-row>
          </v-form>

          <v-card-actions class="actions">
            <v-btn color="primary" outlined @click="goBackToList">
              {{ $t('globals.cancel') }}
            </v-btn>

            <v-btn color="primary" @click="save" :disabled="!canUpdatePartial || !valid">
              {{ $t('globals.save') }}
            </v-btn>
          </v-card-actions>
        </v-tab-item>

        <v-tab-item value="partners">
          <v-card-text>
            <partner-table
              :search="search"
              :can-edit="canUpdatePartners"
              :can-add="canCreatePartner"
              :can-delete="canDeletePartners"
            />
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>

      <upload-pictures
        :dialog="pictureDialog"
        @save="uploadPictures"
        :height="pictureDialogHeight"
        :width="pictureDialogWidth"
        @close="closePictureDialog"
      />
      <upload-files
        :is-multiple="true"
        :dialog="dialogDocuments"
        @save="uploadDocuments"
        @close="closeDialogDocuments"
      />
    </v-container>
  </v-container>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { DateTime } from 'luxon';
import { mapActions, mapGetters } from 'vuex';

import { Conference } from '@/models';
import { ALLOWED_VIDEO_TYPE, VideoType } from '@/models/general-assembly/video-type.model';
import VALIDATORS from '@/helpers/forms/validators.helper';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';

import BaseInputMultiple from '@/components/base-input-multiple/BaseInputMultiple.vue';
import UploadPictures from '@/components/admin/upload-pictures/UploadPictures.vue';
import UploadFiles from '@/components/admin/upload-files/UploadFiles.vue';
import PartnerTable from '@/views/admin/partners/partner-table/PartnerTable.vue';
import InfoTooltip from '@/components/info-tooltip/InfoTooltip.vue';

import {
  GET_CONFERENCE,
  GET_CONFERENCE_ROOMS,
} from '@/stores/agnostic/actions/conference/agnostic-conference.actions';
import {
  SHARED_FILE_MODULE,
  UPLOAD_FILE,
} from '@/stores/shared/actions/file-upload/file-upload.actions';
import {
  ADMIN_CONFERENCE_MODULE,
  CLEAR_CONFERENCE,
  SAVE_CONFERENCE,
} from '@/stores/umanize-admin/actions/conference/admin-conference.actions';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import DateTimePicker from '@/components/datetime-picker/DateTimePicker.vue';

export default {
  name: 'EditConferenceAdmin',
  components: {
    DateTimePicker,
    BaseInputMultiple,
    UploadPictures,
    PartnerTable,
    UploadFiles,
    InfoTooltip,
  },
  data: () => ({
    defaultConference: new Conference(),
    conferenceForm: new Conference(),
    startTime: null,
    endTime: null,
    pictureDialog: false,
    dialogDocuments: false,
    videoTypes: ALLOWED_VIDEO_TYPE,
    conferenceTab: 'conference',
    search: '',
    valid: false,
    rules: {
      videoTypeRequired: VALIDATORS.VIDEO.TYPE.REQUIRED,
      name: [...VALIDATORS.REQUIRED.NAME, VALIDATORS.LENGTH.MIN(3), VALIDATORS.LENGTH.MAX(255)],
    },
    pictureDialogConfig: {
      coverImages: {
        height: 480,
        width: 768,
      },
    },
    pictureDialogType: null,
    pictureDialogHeight: 0,
    pictureDialogWidth: 0,
  }),
  computed: {
    ...mapGetters(ADMIN_CONFERENCE_MODULE, ['conference', 'conferenceRooms']),
    ...mapGetters(SHARED_FILE_MODULE, ['file']),
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUserRoles']),
    endDateRules() {
      if (this.endTime && this.startTime) {
        return [
          ...VALIDATORS.REQUIRED.DATE,
          VALIDATORS.DATE.GREATER_THAN(
            DateTime.fromJSDate(this.startTime),
            this.$t('conference.editModal.startTime'),
          ),
        ];
      }
      return VALIDATORS.REQUIRED.DATE;
    },
    isZoom() {
      return this.conferenceForm.videoType === VideoType.ZOOM;
    },
    isVimeo() {
      return this.conferenceForm.videoType === VideoType.VIMEO;
    },
    isUmanize() {
      return this.conferenceForm.videoType === VideoType.UMANIZE;
    },
    isEditing() {
      return this.$route.path.includes('edit');
    },
    canUpdate() {
      return PermissionsUtil.isAuthorized(
        ['permission.conferences.canUpdate'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
    canUpdatePartial() {
      return (
        this.canUpdate ||
        PermissionsUtil.isAuthorized(
          ['permission.conferences.canUpdatePartial'],
          this.loggedInUserRoles,
          this.$route.params.eventId,
        )
      );
    },
    canViewPartners() {
      return PermissionsUtil.isAuthorized(
        ['permission.partners.canView'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
    canCreatePartner() {
      return PermissionsUtil.isAuthorized(
        ['permission.partners.canCreate'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
    canUpdatePartners() {
      return PermissionsUtil.isAuthorized(
        ['permission.partners.canUpdate'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
    canDeletePartners() {
      return PermissionsUtil.isAuthorized(
        ['permission.partners.canDelete'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
  },
  methods: {
    ...mapActions(ADMIN_CONFERENCE_MODULE, [
      GET_CONFERENCE,
      CLEAR_CONFERENCE,
      SAVE_CONFERENCE,
      GET_CONFERENCE_ROOMS,
    ]),
    ...mapActions(SHARED_FILE_MODULE, [UPLOAD_FILE]),
    initForm() {
      this.conferenceForm = {
        ...this.defaultConference,
        ...cloneDeep(this.conference),
        eventId: this.$route.params.eventId,
      };
      this.startTime = this.conferenceForm.startTime
        ? DateTime.fromISO(this.conferenceForm.startTime).toJSDate()
        : DateTime.local().toJSDate();
      this.endTime = this.conferenceForm.endTime
        ? DateTime.fromISO(this.conferenceForm.endTime).toJSDate()
        : DateTime.local().plus({ hours: 1 }).toJSDate();
    },
    goBackToList() {
      this.$router.back();
    },
    async save() {
      if (!this.$refs.form.validate()) return;
      this.conferenceForm = {
        ...this.conferenceForm,
        startTime: DateTime.fromJSDate(this.startTime),
        endTime: DateTime.fromJSDate(this.endTime),
      };
      await this[SAVE_CONFERENCE](this.conferenceForm);
      this.goBackToList();
    },
    openPictureDialog(pictureType) {
      this.pictureDialogHeight = this.pictureDialogConfig[pictureType].height;
      this.pictureDialogWidth = this.pictureDialogConfig[pictureType].width;
      this.pictureDialog = true;
      this.pictureDialogType = pictureType;
    },
    closePictureDialog() {
      this.dialogPictureType = null;
      this.pictureDialog = false;
    },
    onMultipleChange(key, values) {
      this.conferenceForm[key] = values;
    },

    openDialogDocuments() {
      this.dialogDocuments = true;
    },
    closeDialogDocuments() {
      this.dialogDocuments = false;
    },
    async uploadPictures(pictures) {
      // eslint-disable-next-line no-restricted-syntax
      for await (const picture of pictures) {
        await this[UPLOAD_FILE](picture);
        this.conferenceForm.coverImages = [
          ...(this.conferenceForm.coverImages || []),
          this.file.url,
        ];
      }
      this.closePictureDialog();
    },
    async initializeConference(eventId, conferenceId) {
      await this[GET_CONFERENCE]({
        eventId,
        conferenceId,
      });
    },
    allowedDates(val) {
      return val >= DateTime.fromJSDate(this.startTime).toISODate();
    },
    updateDocuments(documents) {
      this.conferenceForm = {
        ...this.conferenceForm,
        documents: [...documents],
      };
    },
    async uploadDocuments(documents) {
      // eslint-disable-next-line no-restricted-syntax
      for await (const document of documents) {
        await this[UPLOAD_FILE](document);
        this.conferenceForm = {
          ...this.conferenceForm,
          documents: [
            ...(this.conferenceForm.documents || []),
            {
              name: document.name,
              url: this.file.url,
            },
          ],
        };
      }
      this.closeDialogDocuments();
    },
  },
  async mounted() {
    const { eventId, conferenceId } = this.$route.params;
    this[GET_CONFERENCE_ROOMS](eventId);
    if (conferenceId) {
      await this.initializeConference(eventId, conferenceId);
    }
    this.initForm();
  },
  async beforeDestroy() {
    await this[CLEAR_CONFERENCE]();
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../styles/core/variables';
@import '../../../../styles/core/mixins';

@include admin-layout();

.edit-conference {
  padding: 50px 25px;
  min-height: calc(100vh - 6rem);

  &__title {
    font-size: $x-large-font-size;
    font-weight: $regular;
    margin-bottom: 10px;
  }

  &__content {
    @include admin-card;

    margin: 0;
    padding: 20px 40px;
  }
}

.img-container {
  height: 150px;
  width: 150px;
  border: 1px solid var(--v-gray-base);

  p {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
  }
}

.video-url {
  &__title {
    font-weight: $semi-bold;
    font-size: $small-font-size;
    margin-bottom: 0;
  }
}

::v-deep .v-text-field .v-label {
  font-weight: $semi-bold;
}
</style>
