<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon color="primary" dark v-bind="attrs" v-on="on"> mdi-help-circle-outline </v-icon>
    </template>
    <slot></slot>
  </v-tooltip>
</template>

<script>
export default {
  name: 'InfoTooltip',
};
</script>
